import React from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";


const BookingForm = () => {
  const submitHandler = (event) => {
    event.preventDefault();
  };


  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const userInfo = { 
      name: data.name,
      email: data.email,
      phone: data.phone,
      fromaddress: data.fromaddress,
      toaddress : data.toaddress,
      persons : data.persons,
      luggages : data.luggages,
      dates : data.dates,
      picktime : data.picktime,
      message: data.message,
    };
    try {
      await axios.post("https://getform.io/f/bxojgeea", userInfo);
      toast.success("Your message has been sent");
       
    } catch (error) {
      console.log(error);
      
      toast.error("Something went wrong");
    }
  };


  return (
    <Form  action="https://getform.io/f/bxojgeea"
    method="POST" 
    // onSubmit={submitHandler}
    >
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input 
         {...register("name", { required: true })}
        type="text" placeholder="First Name" id="name"name="name" />
         {errors.name && <span>This field is required</span>}
      </FormGroup>
      {/* <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" placeholder="Last Name" />
      </FormGroup> */}

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input 
         {...register("email", { required: true })}
        type="email" placeholder="Email"  name="email" id="email"/>
        {errors.email && <span>This field is required</span>}
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input 
         {...register("phone", { required: true })}
        type="number" placeholder="Phone Number" name="phone" id="phone" />
         {errors.phone && <span>This field is required</span>}
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="text" placeholder="From Address"  name="fromaddress" id="fromaddress"/>
        {errors.fromaddress && <span>This field is required</span>}
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" placeholder="To Address"  name="toaddress" id="toaddress" />
        {errors.toaddress && <span>This field is required</span>}
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select name="persons" id="persons">
          <option value="1 person">1 Person</option>
          <option value="2 person">2 Person</option>
          <option value="3 person">3 Person</option>
          <option value="4 person">4 Person</option>
          <option value="5+ person">5+ Person</option>
        </select>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <select name="luggages" id="luggages">
          <option value="1 luggage">1 luggage</option>
          <option value="2 luggage">2 luggage</option>
          <option value="3 luggage">3 luggage</option>
          <option value="4 luggage">4 luggage</option>
          <option value="5+ luggage">5+ luggage</option>
        </select>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="date" placeholder="Journey Date"  name="dates" id="dates" />
        {errors.dates && <span>This field is required</span>}
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="time"
          placeholder="Journey Time"
          className="time__picker" 
          name="picktime" id="picktime"
        />
         {errors.picktime && <span>This field is required</span>}
      </FormGroup>

      <FormGroup>
        <textarea
          rows={5}
          type="textarea"
          className="textarea"
          placeholder="Write"
          name="message" id="message"
        >
          
        </textarea>
        {errors.message && <span>This field is required</span>}
      </FormGroup>
      <button
              type="submit"
              className="bg-black text-white rounded-xl px-3 py-2 hover:bg-slate-700 duration-300"
            >
              Book NoW
            </button>
    </Form>
  );
};

export default BookingForm;
