import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";
 
import { Link } from "react-router-dom";
import "../../styles/car-item.css";

import driverImg from "../../assets/all-images/toyota-offer-2.png";

const BecomeDriverSection = () => {
  
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="become__driver-img">
            <img src={driverImg} alt="" className="w-100" />
          </Col>

          <Col lg="6" md="6" sm="12">
            <h2 className="section__title become__driver-title">
              Do You Want to Book Your Taxi With Us? <br/>So Don't Be Late 
            </h2>

            <Col lg="4" md="4" sm="6" className="mb-5">
            <Link    to={`/cars/`}>BOOK NOW</Link>
        {/* <button className="btn become__driver-btn mt-4">
              Book Now
            </button>  */}
            </Col> 
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;
