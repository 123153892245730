const serviceData = [
  {
    id: 1,
    title: "Local Taxi Booking",
    icon: "ri-map-pin-2-line",
    desc: "Need a quick ride around town? Our local taxi service offers prompt and reliable transportation within the city. Whether it's a short trip to the market or a visit to a friend, we've got you covered.",
  },

  {
    id: 2,
    title: "Fast and Easy Booking",
    icon: "ri-community-line",
    desc: "Booking a ride has never been easier! With our user-friendly online platform and quick phone booking option, you can reserve your taxi in just a few clicks or a simple call.",
  },

  {
    id: 3,
    title: "One-Way Taxi and Round Trip",
    icon: "ri-roadster-line",
    desc: "Whether you need a one-way ride or a round trip, we offer flexible options to suit your travel needs. Enjoy seamless transportation with our reliable service.",
  },

  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    desc: "Tempor tempor sadipscing vero lorem sea, invidunt sed et eos ipsum et erat. Dolor ut duo sadipscing lorem. Gubergren gub",
  },

  {
    id: 5,
    title: "Tour Booking",
    icon: "ri-map-pin-line",
    desc: "Planning a getaway? Let us handle the details. Our tour booking service offers curated packages for a variety of destinations, ensuring a well-organized and enjoyable trip.",
  },

  {
    id: 6,
    title: "Airport Pick and Drop",
    icon: "ri-flight-takeoff-line",
    desc: "Start and end your journey with ease. Our airport pick-up and drop-off services ensure timely and comfortable transportation to and from the airport, so you can travel stress-free.",
  },
];

export default serviceData;
